export default {
  "calendar": {
    "days": {
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lundi"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mardi"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercredi"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeudi"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendredi"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samedi"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimanche"])}
    },
    "months": {
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Janvier"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Février"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mars"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avril"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juin"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juillet"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Août"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Septembre"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Octobre"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novembre"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décembre"])}
    },
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semaine"])}
  },
  "daymenu": {
    "starters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrées"])},
    "mains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plats"])},
    "sides": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garnitures"])},
    "dairy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laitages"])},
    "desserts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desserts"])},
    "bread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pain"])},
    "breadunit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pain à l'unité"])},
    "finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publié"])},
    "lunch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MIDI"])},
    "dinner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SOIR"])},
    "addok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu ajouté avec succès"])},
    "allmenus_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MIDI (Tous)"])},
    "allmenus_s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SOIR (Tous)"])},
    "allergies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des allergènes"])},
    "allergies_filename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LISTE_ALLERGENES"])},
    "backtocalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour au calendrier"])},
    "calendar_byday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menus du jour"])},
    "calendar_bymonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menus totaux"])},
    "error_checkboxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez choisi le nombre maximal d'options pour cette partie. Décochez d'abord une option avant de pouvoir en choisir une autre. "])},
    "error_menustotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre d'éléments commandés est supérieur au chiffre rentré. Veuillez corriger d'abord les éléments du menu"])},
    "error_qty_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention, il y a des différences entre le nombre de menus souhaités et les quantités commandées (cela est non bloquant)"])},
    "error_qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La somme demandée ne peut excéder le nombre de menus."])},
    "deliveryforms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bons de livraison"])},
    "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
    "fileadded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier ajouté"])},
    "filedeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier supprimé"])},
    "fileupdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier mis à jour"])},
    "limit_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date limite d'administration : "])},
    "limit_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date limite de souscription: "])},
    "managingforentity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandes pour: "])},
    "meatorigin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origine des viandes"])},
    "meatorigin_filename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ORIGINE_VIANDE"])},
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
    "menus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menus"])},
    "menustotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de menus"])},
    "nomenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de menu disponible pour cette date"])},
    "notfinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non publié"])},
    "notvalidated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce menu du jour est en attente de validation"])},
    "orderaddok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande ajoutée"])},
    "orderedmenus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["menus commandés"])},
    "orderok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande enregistrée"])},
    "saveok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu enregistré"])},
    "selectmenustotal_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez rentrer un nombre de menus du midi"])},
    "selectmenustotal_s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez rentrer un nombre de menus du soir"])},
    "showorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les commandes"])},
    "subscribe_entity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souscriptions par entité"])},
    "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce menu du jour a été validé"])},
    "weekmenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu de la semaine"])},
    "weekmenu_lunch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu de la semaine (Midi)"])},
    "weekmenu_dinner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu de la semaine (Soir)"])}
  },
  "footer": {
    "allrights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CDIL Consulting - Tous droits réservés"])}
  },
  "home": {
    "accesscalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accéder au calendrier"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sur le site de commande "])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["des Fourneaux de Marthe & Matthieu"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue"])}
  },
  "login": {
    "cantlogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier vos identifiants."])},
    "checkfield": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier les champs."])},
    "fieldnotblank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ doit être renseigné"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour commencer, veuillez vous identifier :"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'identifier"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès à votre espace"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte utilisateur"])}
  },
  "main": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé"])},
    "created_f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créée"])},
    "clickhere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez-ici"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
    "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargement"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer"])},
    "erroroccurred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite:"])},
    "errorcontactadmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez contacter votre administrateur si le problème persiste."])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["du"])},
    "generating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Génération en cours..."])},
    "global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["global"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["information"])},
    "lastupdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière modification"])},
    "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
    "showdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir le détail"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récap"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total"])},
    "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
    "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validé"])},
    "uat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CECI EST UN SITE DE TESTS - Pour une utilisation en production"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifié"])},
    "updated_f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifiée"])},
    "uploadfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])}
  },
  "menu": {
    "collective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restauration Collective"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
    "horizons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizons fait maison"])},
    "admon": {
      "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
      "accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comptes utilisateur"])},
      "entities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sites"])}
    }
  },
  "admon": {
    "accounts": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un compte"])},
      "addok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur ajouté"])},
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateur"])},
      "can_order_collective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collective"])},
      "can_order_horizons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizons"])},
      "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
      "confirm_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etes-vous sûr de vouloir supprimer le compte de :"])},
      "confirm_delete_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etes-vous sûr de vouloir supprimer ce compte"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte créé pour: "])},
      "date_joined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'utilisateur"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte supprimé"])},
      "deleteok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur supprimé"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer l'utilisateur"])},
      "editok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur édité"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "entity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entité"])},
      "errors": {
        "It is mandatory to provide a last name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom est obligatoire"])},
        "It is mandatory to provide a first name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le prénom est obligatoire"])},
        "It is mandatory to provide a username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom d'utilisateur est obligatoire"])},
        "It is mandatory to provide a password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe est obligatoire"])},
        "It is mandatory to provide a role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le rôle est obligatoire"])},
        "It is mandatory to provide an entity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'entité est obligatoire"])},
        "It is mandatory to provide an e-mail address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'adresse e-mail est obligatoire"])},
        "An account with this email already exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un compte avec cette addresse e-mail existe déjà"])},
        "This field may not be blank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ ne peut pas être vide"])},
        "This field is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ est obligatoire"])},
        "The passwords do not match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne correspondent pas"])},
        "It is mandatory to confirm the password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La confirmation du mot de passe est obligatoire"])},
        "The password must have at least 8 characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit contenir au moins 8 caractères"])},
        "The password must have at least one number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit contenir au moins un chiffre"])},
        "The password must have at least one uppercase letter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit contenir au moins une lettre majuscule"])},
        "The password must have at least one lowercase letter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit contenir au moins une lettre minuscule"])},
        "Only admins can manage accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seuls les administrateurs peuvent gérer les comptes utilisateur"])},
        "You cannot delete your own account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas supprimer votre propre compte"])},
        "You cannot delete this account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas supprimer ce compte"])}
      },
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "last_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière connexion"])},
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau compte"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
      "passwordconfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le mot de passe"])},
      "passwordnotmatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne correspondent pas"])},
      "passwordrequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe est requis"])},
      "passwordtooshort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit contenir au moins 6 caractères"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rôle"])},
      "roleadmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateur"])},
      "roleuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration des comptes utilisateur"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" mis à jour."])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])}
    },
    "entities": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un site"])},
      "addok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur ajouté"])},
      "confirm_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etes-vous sûr de vouloir supprimer :"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site créé avec succès"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le site"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site supprimé avec succès"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration des sites"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer le site"])},
      "editok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site édité"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du site"])},
      "open_date_limits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edition des dates ouverte"])},
      "open_date_limits_closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edition des dates fermée"])},
      "open_date_limits_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de limite de date"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" mis à jour"])}
    }
  }
}